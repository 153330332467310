.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 4.722222vh;
  margin-left: auto;
  margin-right: auto;
}

.img-last {
  margin-right: 0px !important;
}

.image-item {
  animation: slideInUp 1s;
  animation-timing-function: forwards;
  transform: translateY(100%);
  animation-fill-mode: both;
  width: 270px;
  height: 269px;
  margin-right: 20px;

  &:nth-child(2) {
    animation-delay: 0.75s;
  }

  &:nth-child(3) {
    animation-delay: 1.5s;
  }

  &:nth-child(4) {
    animation-delay: 2.25s;
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
