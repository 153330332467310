.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
  z-index: 999;
}

.modalButton {
  border: none;
  background-color: transparent;
  z-index: 1001;
  position: absolute;
  left: 100%;
  top: 0;
  transform: translate(-120%, 20%);
}

.modalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.modalContainer {
  background: #1c1c1d;
  border-radius: 8px;
  padding: 27px 18px 24px 17px;
}

.modalGradient {
  background: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  border-radius: 8px;
  opacity: 0.8;
  padding: 1px;
}
